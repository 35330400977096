<template>
    <div>
        <el-container>
            <el-header height="80px">
                <CommonHeader></CommonHeader>
            </el-header>
            <el-container>
                <el-aside width="auto">
                    <CommonAside></CommonAside>
                </el-aside>
                <router-view></router-view>
            </el-container>
            <!-- <el-footer class="footer" height="40px">
                <el-link href="https://beian.miit.gov.cn/" target="_blank"
                    style="color: rgba(34,52,100,1);font-size: 14px;text-decoration: underline;">
                    京ICP备2023012817号-1</el-link>
            </el-footer> -->
        </el-container>
    </div>
</template>

<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'

export default {
    data() {
        return {

        }
    },
    components: {
        CommonAside, CommonHeader
    }
}
</script>

<style>
.el-header {
    padding: 0;

    h3 {
        color: #ffff;
        text-align: center;
        line-height: 48px;
        font-size: 30px;
        font-weight: 400px;
    }
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    /* 确保footer有足够的高度来居中对齐 */
}
</style>
