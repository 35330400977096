export default {
    state: {
        isCollapse: true,//控制菜单的展开还是收起
        userInfo: null,//用户信息
        userLoginDialogVisible: false//用户登录弹窗显示
    },
    mutations: {
        //修改菜单展开收起的方法
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        //设置用户信息
        setUserInfo(state, info) {
            state.userInfo = info
        },
        //设置用户登录弹窗显示
        setUserLoginDialogVisible(state, visible) {
            state.userLoginDialogVisible = visible
        }
    }
}