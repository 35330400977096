<template>
    <div>
        <el-card class="box-content1">
            <div class="title">基本资料</div>
            <div class="line"></div>
            <div class="image">
                <div class="content">头像</div>
                <img v-if="localImageFileUrl" :src="localImageFileUrl" alt="">
                <div>
                    <el-upload :show-file-list="false" action="" :before-upload="beforeUpload" :limit="1"
                        :auto-upload="true">
                        <el-button size="small" round @click="selectImage">选择头像</el-button>
                    </el-upload>
                    <div style="margin-top: 10px;color: rgba(154,154,154,1);font-size: 14px;">
                        支持 JPG、GIF、PNG 格式图片，不超过 5M
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="image">
                <div class="content">昵称</div>
                <el-input type="text" maxlength="20" style="width: 200px;" show-word-limit v-model="userName"
                    placeholder="请输入昵称"></el-input>
            </div>
            <div class="line"></div>
            <el-button style="margin-top: 20px;" type="primary" @click="submitUpdate">更新资料</el-button>
        </el-card>
        <el-card class="box-content2">
            <div class="title">账号安全</div>
            <div class="line"></div>
            <div class="image">
                <div class="content">手机号</div>
                <div style="width: 200px;color: rgba(79,79,79,1);font-size: 16px;text-align: left;">
                    {{ userInfo.phoneNumber }}
                </div>
                <el-button type="primary" disabled>更换手机</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import { uploadFileWithFilePath, updateUserInfo } from "../api";
import { mapState } from 'vuex';

export default {
    data() {
        return {
            userName: '',
            localImageFileUrl: null,
            localImageFile: null,
            phoneNumber: "139****8435",
        };
    },
    methods: {
        selectImage() {
            return true;
        },
        beforeUpload(file) {
            const isJpgOrPngOrGif = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isJpgOrPngOrGif) {
                this.$message.error('上传头像图片只能是 JPG、GIF、PNG 格式!');
                return false;
            }
            if (!isLt5M) {
                this.$message.error('上传头像图片大小不能超过 5MB!');
                return false;
            }
            let blob = new Blob([file]); // 返回的文件流数据
            let url = window.URL.createObjectURL(blob); // 将他转化为路径
            this.localImageFileUrl = url;
            this.localImageFile = file;
            console.log('Selected file:', this.localImageFile); // 打印文件信息
            return true;
        },

        async uploadImageFile() {
            if (this.localImageFile) {
                const remark = "用户头像上传";
                try {
                    const response = await uploadFileWithFilePath(this.localImageFile, remark);
                    if (response.data.code !== 0) {
                        this.$message.error(response.data.message);
                        throw new Error(response.data.message);
                    } else {
                        this.localImageFileUrl = response.data.data;
                    }
                } catch (error) {
                    if (error.response) {
                        console.error('上传失败:', error.response);
                        this.$message.error("上传失败: " + (error.response.data.message || error.message));
                    } else {
                        console.error('上传失败:', error);
                        this.$message.error("上传失败: " + error.message);
                    }
                    throw error;
                }
            } else {
                this.$message.error("请先选择图片");
                throw new Error("No image selected");
            }
        },

        async updateUserInfo() {
            const userID = this.userInfo.id;
            const nickName = this.userName;
            const url = this.localImageFileUrl;
            const isDelete = false; // 根据实际需求设置
            try {
                const response = await updateUserInfo(userID, nickName, url, isDelete);
                if (response.data.code !== 0) {
                    this.$message.error(response.data.message);
                    throw new Error(response.data.message);
                } else {
                    this.$message.success("资料更新成功");
                    setTimeout(() => {
                        this.$router.go(0);
                    }, 1000); // 延迟1秒后刷新页面
                }
            } catch (error) {
                this.$message.error("资料更新失败: " + (error.response.data.message || error.message));
                throw error;
            }
        },

        async submitUpdate() {
            try {
                if (this.localImageFile) {
                    await this.uploadImageFile();
                }
                await this.updateUserInfo();
            } catch (error) {
                console.error("更新资料失败:", error);
            }
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo || {}, // 如果 state.tab.userInfo 为 null，则使用一个空对象
        }),
    },
    mounted() {
        this.localImageFileUrl = this.userInfo.url || ''; // 如果 userInfo.url 为 null，则使用空字符串
        this.userName = this.userInfo.nickName || ''; // 如果 userInfo.nickName 为 null，则使用空字符串
    }
}
</script>

<style scoped>
.box-content1 {
    padding-left: 20px;
    margin-bottom: 20px;
    height: 380px;
}

.box-content2 {
    padding-left: 20px;
    height: 400px;
}

.title {
    height: 26px;
    line-height: 36px;
    color: rgba(79, 79, 79, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
}

.line {
    margin-top: 20px;
    height: 1px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.content {
    width: 80px;
    height: 26.27px;
    line-height: 28px;
    color: rgba(79, 79, 79, 1);
    font-size: 20px;
    text-align: left;
}

.image {
    margin-top: 20px;
    display: flex;
    align-items: center;

    img {
        margin-right: 20px;
        width: 84px;
        height: 84px;
        border-radius: 50%;
    }
}
</style>
