<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  mounted() {
    document.title = "一站财经";
  }
}
</script>

<style>
html,
body,
h3,
p {
  margin: 0;
  padding: 0;
}
</style>
