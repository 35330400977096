<template>
    <div class="header-container">
        <div class="l-content">
            <el-button style="margin-right: 20px;" @click="handleMenu" icon="el-icon-s-unfold" size="mini"></el-button>
            <span class="text">一站财经</span>
        </div>
        <div class="r-content">
            <!-- 当 userInfo 存在时，显示用户信息和下拉菜单 -->
            <el-dropdown v-if="userInfo" trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                    <img class="user" :src="userInfo.url">
                    <span style="color: rgba(0,0,0,1);font-size: 18px;">{{ userInfo.nickName }}</span>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="myCenter">个人中心</el-dropdown-item>
                    <el-dropdown-item command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- 当 userInfo 不存在时，显示登录/注册提示 -->
            <div v-else class="login" @click=" setUserLoginDialogVisible(true)">
                登录/注册
            </div>
        </div>
        <el-dialog :visible.sync="userLoginDialogVisible" height="520px" width="450px" :before-close="handleClose">
            <CommonLogin></CommonLogin>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Cookie from 'js-cookie'
import CommonLogin from "../components/CommonLogin.vue"
export default {
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        handleMenu() {
            this.$store.commit('collapseMenu')
        },
        handleCommand(command) {
            if (command == 'logout') {
                this.setUserInfo(null)
                Cookie.remove('token');
                if (this.$route.path !== '/home') {
                    this.$router.push('/home')
                }
            }
            else if (command == 'myCenter') {
                if (this.$route.path !== '/user') {
                    this.$router.push('/user')
                }
            }
        },
        handleClose(done) {
            this.setUserLoginDialogVisible(false)
        },
        ...mapMutations(['setUserInfo', 'setUserLoginDialogVisible']),
    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
            userLoginDialogVisible: state => state.tab.userLoginDialogVisible
        }),

    },
    components: {
        CommonLogin
    }
}
</script>

<style lang="less" scoped>
.header-container {
    height: 79px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);

    .l-content {
        display: flex;
        align-items: center;
    }

    .text {
        margin-right: 20px;
        color: #00336C;
        text-align: center;
        line-height: 48px;
        font-size: 40px;
        font-weight: bold;
        user-select: none;
    }

    .r-content {
        cursor: pointer;

        .el-dropdown-link {
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 16px;
        }

        .user {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            padding: 10px;
        }

        .login {
            width: 80px;
            padding: 10px;
        }
    }

    .loginDialog {
        height: 520px;
        width: 450px;
        background-color: rgba(251, 251, 251, 1);
    }
}
</style>
