<template>
    <div class="home">
        <div class="userPage">
            <el-row>
                <el-col :span="6" style="padding-right: 30px;">
                    <el-card class="box-card">
                        <div class="user">
                            <img :src="userInfo.url" alt="">
                            <div class="userinfo">
                                <p class="name">{{ userInfo.nickName }}</p>
                                <div v-show="userInfo.roleName == 'vip'" class="vip">
                                    <p class="access">vip</p>
                                    <p class="viptime">到期时间：{{ vipTime }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="target-Button">
                            <el-menu default-active="2" class="el-menu" @select="handleSelect">
                                <el-menu-item index="资料设置">
                                    <i class="el-icon-setting"></i>
                                    <span class="button-title" slot="title">资料设置</span>
                                </el-menu-item>
                                <el-menu-item index="礼品卡">
                                    <i class="el-icon-present"></i>
                                    <span class="button-title" slot="title">礼品卡</span>
                                </el-menu-item>
                            </el-menu>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="18" style="padding-left: 30px;">
                    <CommonUserInfo v-if="selectInfo == '资料设置'"></CommonUserInfo>
                    <CommonUserInfoGiftCard v-if="selectInfo == '礼品卡'"></CommonUserInfoGiftCard>


                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>

import CommonUserInfo from "../components/CommonUserInfo.vue"
import CommonUserInfoGiftCard from "../components/CommonUserInfoGiftCard.vue"


import { getVipInfoDate, loginWithVerificationCode, loginWithPassword } from "../api"
import { mapState, mapMutations } from 'vuex';
export default {
    data() {
        return {
            vipTime: "",
            selectInfo: "资料设置"
        }
    },
    methods: {
        //点击菜单
        handleSelect(key, keyPath) {
            this.selectInfo = key;
        },
        FormatDate(dateStr) {
            const date = new Date(dateStr);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        //显示错误信息
        ErrorMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'error'
            });
        },
    },
    mounted() {
        getVipInfoDate().then((data) => {
            if (data.data.code !== 0) {
                this.ErrorMessage(data.data.message);
            }
            else {
                this.vipTime = this.FormatDate(data.data.data);
            }
        })

    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
        }),
    },
    components: {
        CommonUserInfo, CommonUserInfoGiftCard
    }
}
</script>

<style lang="less" scoped>
.home {
    background-color: rgba(251, 251, 251, 1);
    height: 1200px;
    width: 100%;
}

.userPage {
    margin: 20px;
    margin-top: 50px;
    height: 100%;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
}

.box-card {
    height: 800px;
}

.user {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);

    img {
        margin-right: 20px;
        width: 84px;
        height: 84px;
        border-radius: 50%;
    }

    .userinfo {
        .name {
            margin-bottom: 10px;
            font-family: Roboto;
            line-height: 39px;
            color: rgba(79, 79, 79, 1);
            font-size: 28px;
        }

        .vip {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .access {
                background-color: rgba(237, 240, 252, 1);
                color: rgba(79, 109, 226, 1);
                font-size: 14px;
                text-align: center;
                width: 40px;
                font-family: Roboto;
                line-height: 20px;
                border-radius: 3px;
                margin-right: 10px;
            }

            .viptime {
                margin-top: 5px;
                width: 169px;
                height: 16px;
                line-height: 17px;
                color: rgba(79, 79, 79, 1);
                font-size: 12px;
                text-align: left;
                font-family: PingFangSC-regular;
            }
        }
    }
}

.el-menu {
    border: 0px solid rgba(239, 239, 239, 1);

    .button-title {
        margin-left: 5px;
        font-size: 16px;
    }

    .el-menu-item.is-active {
        color: rgba(79, 79, 79, 1);
        font-weight: bold;
    }
}
</style>
