import { render, staticRenderFns } from "./CommonNewsPdfDetail.vue?vue&type=template&id=27a4c6db&scoped=true"
import script from "./CommonNewsPdfDetail.vue?vue&type=script&lang=js"
export * from "./CommonNewsPdfDetail.vue?vue&type=script&lang=js"
import style0 from "./CommonNewsPdfDetail.vue?vue&type=style&index=0&id=27a4c6db&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a4c6db",
  null
  
)

export default component.exports