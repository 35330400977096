<template>
    <div class="login">
        <div class="intruction" style="margin-left: 15%;user-select: none;">
            <div style="color: rgba(34,52,100,1);font-size: 72px;text-align: left;font-weight: bold;">
                新闻直播间
            </div>
            <div style="color: rgba(34,52,100,1);font-size: 72px;text-align: left;font-weight:bold;">
                Hello，News！
            </div>
            <div style="line-height: 93px;color: rgba(34,52,100,1);font-size: 28px;text-align: left;">
                用AI解析咨询，让洞察触手可及。
            </div>
        </div>
        <el-card class="card" style="margin-right: 15%;">
            <CommonLogin></CommonLogin>
        </el-card>

    </div>
</template>

<script>
import CommonLogin from "../components/CommonLogin.vue"

export default {
    data() {
        return {

        };
    },
    components: {
        CommonLogin
    }

};
</script>

<style lang="less" scoped>
.login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10%;
    background-color: rgba(255, 255, 255, 1);
}

.card {
    height: 520px;
    width: 450px;
    background-color: rgba(251, 251, 251, 1);
}
</style>