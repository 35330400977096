export default {
    state: {
        alertNewsList: [],//用户检索的新闻
    },
    mutations: {
        //设置用户检索的新闻
        setAlertNewsList(state, alertNewsList) {
            state.alertNewsList = alertNewsList
        },
        //在开始插入用户检索的新闻
        insertAlertNewsAtFirst(state, insertAlertNewsList) {
            state.alertNewsList.unshift(...insertAlertNewsList);
        },
        //在末尾插入用户检索的新闻
        insertAlertNewsAtEnd(state, insertAlertNewsList) {
            state.alertNewsList.push(...insertAlertNewsList);
        },
    }
}