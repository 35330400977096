<template>
    <div class="container" @click="showRandomQuote">
        <div class="content">
            {{ currentQuote }}
        </div>
        <div style=" font-size: 36px;font-weight: bold;color: #2c3e50;text-align: center;padding: 20px;">
            开发中... </div>
    </div>
</template>

<script>


export default {
    name: 'Home',
    components: {

    },
    data() {
        return {
            quotes: [
                "似此星辰非昨夜，为谁风露立中宵。",
                "此夜曲中闻折柳，何人不起故园情。",
                "此情可待成追忆，只是当时已惘然。",
                "追往事，去无痕。梦里不知身是客，一晌贪欢。",
                "无可奈何花落去，似曾相识燕归来。",
                "这次第，怎一个愁字了得！",
                "银烛秋光冷画屏，轻罗小扇扑流萤。",
                "相见时难别亦难，东风无力百花残。",
                "江畔何人初见月？江月何年初照人？",
                "东风夜放花千树。更吹落，星如雨。",
                "花褪残红青杏小。燕子飞时，绿水人家绕。",
                "无边落木萧萧下，不尽长江滚滚来。",
                "床前明月光，疑是地上霜。",
                "无言独上西楼，月如钩。",
                "十年生死两茫茫，不思量，自难忘。",
                "海上生明月，天涯共此时。"
            ],
            currentQuote: "似此星辰非昨夜，为谁风露立中宵。"
        }
    },
    mounted() {
        this.showRandomQuote();
    },
    methods: {
        showRandomQuote() {
            const randomIndex = Math.floor(Math.random() * this.quotes.length);
            this.currentQuote = this.quotes[randomIndex];
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(251, 251, 251, 1);
    width: 100%;
    cursor: pointer;
}

.content {
    font-size: 36px;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
    padding: 20px;
    border: 2px solid #2c3e50;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}
</style>
