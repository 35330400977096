<template>
    <div class="news" :class="{ 'show-background': news.isShowBackground }" @dblclick="click">
        <div>
            <div class="circle"></div>
        </div>
        <div v-if="isShowRed">
            <div>
                <div style="font-weight: bold; color: rgba(34,52,100,1);" :style="textStyle"> {{ formattedTime }} </div>
                <div v-if="showFormattedDate" style="color:rgba(34,52,100,1);font-size: small;color: rgba(99,99,99,1);"
                    :style="textStyle">
                    {{ formattedDate }}
                </div>
                <div style="margin-top: 5px; color: rgba(34,52,100,1);" :style="textStyle">{{ news.source }}</div>
            </div>
        </div>
        <div v-else>
            <div style="font-weight: bold; color: rgba(34,52,100,1);"> {{ formattedTime }}</div>
            <div v-if="showFormattedDate" style="color:rgba(34,52,100,1);font-size: small;color: rgba(99,99,99,1);">
                {{ formattedDate }}
            </div>
            <div style="margin-top: 5px; color: rgba(34,52,100,1);">{{ news.source }}</div>
        </div>
        <div style="color: rgba(99,99,99,1);">
            <div v-if="news.title" style="margin-bottom: 5px; font-weight: bold; text-align: justify;">
                <span v-if="isShowRed" :style="textStyle">{{ news.title }}</span>
                <span v-else v-html="highlightText(news.title, searchText, isShowNewsSearch)"></span>
            </div>
            <div style="text-align: justify; line-height: 1.4">
                <span v-if="isShowRed" :style="textStyle">{{ news.content }}</span>
                <span v-else v-html="highlightText(news.content, searchText, isShowNewsSearch)"></span>
            </div>
            <div v-if="PicUrlArray && PicUrlArray.length" style="display: flex; flex-wrap: wrap;  margin-top: 10px;">
                <el-image v-for="(url, index) in visiblePicUrls" :key="index"
                    style="width: 400px; height: auto ;margin-right:10px" :src="url" fit="contain"
                    :preview-src-list="PicUrlArray"> </el-image>
                <div v-if="PicUrlArray.length > 1"
                    style="width: 50px; height: 75px; display: flex; align-items: center; justify-content: center; font-size: 64px; color: #999;">
                    ...
                </div>
            </div>
            <div style="display: flex;align-items: flex-end; justify-content: space-between; margin-top: 8px;">
                <div style="display: flex; justify-content: flex-end; ">
                    <div v-for="(newsAlert, index)  in newsAlertList" :key="`${newsAlert.alertStr}-${index}`">
                        <el-tag style="margin-right: 8px;" size="small">{{ newsAlert.alertStr }}</el-tag>
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end;">
                    <el-button round size="mini" style="align-items: center; font-size: small; color: rgba(99,99,99,1);"
                        @click="click">
                        <i class="el-icon-reading" style="margin-right: 2px;"></i> 查看详情
                    </el-button>
                    <el-button round size="mini" style="align-items: center; font-size: small; color: rgba(99,99,99,1);"
                        @click="copyToClipboard">
                        <i class="el-icon-share" style="margin-right: 2px;"></i> 复制
                    </el-button>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" height="100%" width="min(60%, 1200px)">
            <CommonNewsPdfDetail v-if="news.source == '公司公告'" :news="news"></CommonNewsPdfDetail>
            <CommonNewsUniversaDetail v-else :news="news"></CommonNewsUniversaDetail>
        </el-dialog>
    </div>
</template>

<script>
import CommonNewsUniversaDetail from "./CommonNewsUniversaDetail.vue";
import CommonNewsPdfDetail from "./CommonNewsPdfDetail.vue"

export default {
    props: {
        news: {
            type: Object,
            required: true
        },
        newsAlertList: {
            type: Array,
            required: true
        },
        searchText: {
            type: String,
            default: ''
        },
        isShowNewsSearch: {
            type: Boolean,
            default: false
        },
        //是否显示重要信息
        isRedImportentNews: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            PicUrlArray: [],
            dialogVisible: false,
            isShowRed: false,
            E: {
                score: 8,
                category: "宏观",
                relevantCompany: "",
                relevantIndustry: "",
                picUrls: "",
                content: "中国物流与采购联合会在第三届中国政企采购数字供应链生态峰会上发布《中国公共采购发展报告（2023）》，全国公共采购总额46万亿元，同比下降4.16%。报告显示，2023年我国公共资源交易工程建设项目招标投标、政府采购、土地使用权和矿业权出让、国有产权交易四大板块交易数量176万个，同比增长17%；交易额达21.9万亿元。",
                title: "46万亿！去年我国公共采购“真金白银”花在这些地方",
                dateTime: "2024-05-10T13:54:42",
                source: "新闻资讯",
                web: ""
            }
        };
    },
    methods: {
        click() {
            this.dialogVisible = true;
        },
        redImportentNews() {
            if (this.isRedImportentNews && this.news.score > 6) {
                this.isShowRed = true
            }
        },
        highlightText(content, searchText, isShowNewsSearch) {
            if (!isShowNewsSearch || !searchText) {
                return content;
            }
            const regex = new RegExp(`(${searchText})`, 'gi');
            return content.replace(regex, '<span style="color:rgba(189,49,36,1);font-weight: bold;">$1</span>');
        },
        processNewsUrlString() {
            // 判断picUrls是否为空或者不是字符串
            if (!this.news.picUrls || typeof this.news.picUrls !== 'string') {
                // 当picUrls为空或不是字符串时，将PicUrlArray设为null
                this.PicUrlArray = null;
            } else {
                // 使用分号作为分隔符，将字符串分割成数组
                this.PicUrlArray = this.news.picUrls.split(';');
            }
        },
        //保存分享的新闻
        copyToClipboard() {
            let data = this.news.content;
            if (this.news.title) {
                data = `【${this.news.title}】${this.news.content}`;
            }
            data += ` www.askition.com`;

            navigator.clipboard.writeText(data).then(() => {
                this.$message({
                    message: '新闻已保存至剪贴板',
                    type: 'success'
                });
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        }
    },
    computed: {
        formattedTime() {
            const date = new Date(this.news.dateTime);
            return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');
        },
        formattedDate() {
            const date = new Date(this.news.dateTime);
            return date.getFullYear() + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getDate().toString().padStart(2, '0');
        },
        showFormattedDate() {
            const givenDate = new Date(this.news.dateTime);
            const currentDate = new Date();
            return givenDate.getFullYear() !== currentDate.getFullYear() ||
                givenDate.getMonth() !== currentDate.getMonth() ||
                givenDate.getDate() !== currentDate.getDate();
        },
        textStyle() {
            return this.isShowRed ? { color: 'rgba(189, 49, 36, 1)' } : { color: 'rgba(99, 99, 99, 1)' };
        },
        visiblePicUrls() {
            // 返回最多3个URL
            return this.PicUrlArray.slice(0, 2);
        },
        isShowBackground() {
            // 如果 news 中没有 isShowBackground 属性，返回 false 作为默认值
            return this.news.isShowBackground ?? false;
        }
    },
    components: {
        CommonNewsUniversaDetail, CommonNewsPdfDetail
    },
    mounted() {
        this.redImportentNews()
    },
    created() {
        // 在组件创建完成后，立即处理字符串
        this.processNewsUrlString();
    },
};
</script>

<style lang="less" scoped>
.news {
    padding: 10px;
    padding-top: 30px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 20px 90px 1fr;
    margin-left: 20px;
    margin-bottom: 10px;

    &.show-background {
        background-color: rgba(255, 248, 29, 0.1);
    }

    .circle {
        margin-top: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 1);
        border: 2px solid rgba(34, 52, 100, 1);
    }
}
</style>
