import { render, staticRenderFns } from "./CommonNewsUniversaDetail.vue?vue&type=template&id=4cf246c7&scoped=true"
import script from "./CommonNewsUniversaDetail.vue?vue&type=script&lang=js"
export * from "./CommonNewsUniversaDetail.vue?vue&type=script&lang=js"
import style0 from "./CommonNewsUniversaDetail.vue?vue&type=style&index=0&id=4cf246c7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf246c7",
  null
  
)

export default component.exports