import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
import User from "../views/User.vue"
import Main from "../views/Main.vue"
import FinancialGPT from "../views/FinancialGPT.vue"
import Login from "../views/Login.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/home',//重定向
    children: [
      { path: 'home', name: 'home', component: Home },
      { path: 'user', name: 'user', component: User },
      { path: 'financialGPT', name: 'FinancialGPT', component: FinancialGPT }
    ]
  },
  {
    path: '/login', name: 'login', component: Login
  }
]

const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

export default router
