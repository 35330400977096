<template>
    <el-menu style="min-height: 1000px;" default-active="1-4-1" background-color="#00336C" text-color="#fff"
        active-text-color="#ffd04b" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse">
        <div style="margin-top: 10px;"></div>
        <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
        </el-menu-item>
        <el-submenu v-for="item in hasClildren" :key="item.label" :index="item.label">
            <template slot="title">
                <i :class="`el-icon-${item.icon}`"></i>
                <span slot="title">{{ item.label }}</span>
            </template>
            <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{ subItem.label }}</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</template>


<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 140px;
    min-height: 400px;
}

.el-menu {
    height: 100%;
}
</style>

<script>
export default {
    data() {
        return {
            menuData: [
                {
                    path: "/home",
                    name: "home",
                    label: "实时财经",
                    icon: "s-home",
                    url: "Home/Home",
                },
                {
                    path: "/financialGPT",
                    name: "financialGPT",
                    label: "财经GPT",
                    icon: "chat-line-round",
                    url: "financialGPT/financialGPT",
                },
                // {
                //     path: "/user",
                //     name: "user",
                //     label: "用户管理",
                //     icon: "user",
                //     url: "UserManage/UserManage",
                // },
                // {
                //     label: "其他",
                //     icon: "location",
                //     children: [
                //         {
                //             path: "/page1",
                //             name: "page1",
                //             label: "页面1",
                //             icon: "setting",
                //             url: "Other/PageOne",
                //         },
                //         {
                //             path: "/page2",
                //             name: "page2",
                //             label: "页面2",
                //             icon: "setting",
                //             url: "Other/PageTwo",
                //         },
                //     ],
                // },
            ]
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        //点击菜单
        clickMenu(item) {
            if (this.$route.path !== item.path && !(this.$route.path == '/home' && (item.path == '/'))) {
                this.$router.push(item.path)
            }
        }
    },
    computed: {
        //没有子菜单
        noChildren() {
            return this.menuData.filter(item => !item.children)
        },
        //有子菜单
        hasClildren() {
            return this.menuData.filter(item => item.children)
        },
        isCollapse() {
            return this.$store.state.tab.isCollapse
        }
    }
}
</script>