<template>
    <div>
        <el-card class="box-content1">
            <div class="title">礼品卡</div>
            <div class="line"></div>
            <div class="image">
                <div class="content">请输入兑换码</div>
                <el-input type="text" style="width: 100%; margin-right: 40px;" show-word-limit v-model="giftCardStr"
                    placeholder="兑换码以sk开头"></el-input>
            </div>
            <div class="button-container">
                <el-button style="margin-top:20px;" type="primary" @click="GiftCodeVerification">兑换</el-button>
            </div>
            <div class="button-container" style="margin-top:10px;">
                <el-link href="https://222pt.cn/swsgf" target="_blank"
                    style="line-height: 20px;color: rgba(34,52,100,1);font-size: 14px;text-align: left;text-decoration: underline;">
                    如何获取兑换码？</el-link>
            </div>
        </el-card>
    </div>
</template>

<style lang="less" scoped>
.box-content1 {
    padding-left: 20px;
    margin-bottom: 20px;
    height: 800px;
}

.title {
    height: 26px;
    line-height: 36px;
    color: rgba(79, 79, 79, 1);
    font-size: 20px;
    text-align: left;
    font-weight: bold;
}

.line {
    margin-top: 20px;
    height: 1px;
    border-bottom: 1px solid rgba(239, 239, 239, 1);
}

.content {
    width: 200px;
    height: 26.27px;
    line-height: 28px;
    color: rgba(79, 79, 79, 1);
    font-size: 20px;
    text-align: left;
}

.image {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.button-container {
    display: flex;
    justify-content: center;
}
</style>

<script>
import { userGiftCardVerificationCode, getTokenInformation } from "../api"
import { mapState, mapMutations } from 'vuex';
import store from '../store'
import Cookie from 'js-cookie'
export default {
    data() {
        return {
            giftCardStr: ""
        };
    },
    methods: {
        //发送验证码     
        GiftCodeVerification() {
            if (this.giftCardStr) {
                userGiftCardVerificationCode(this.giftCardStr).then((data) => {
                    if (data.data.code !== 0) {
                        this.ErrorMessage(data.data.message);
                    }
                    else {
                        this.SuccessMessage("礼品卡兑换成功！请刷新页面。")
                        //更新用户信息
                        const token = Cookie.get('token')
                        if (token) {
                            getTokenInformation(token).then((data) => {
                                if (data.data.code !== 200) {
                                    Cookie.remove('token')
                                }
                                else {
                                    store.commit('setUserInfo', data.data.data)
                                }
                            })
                        }
                    }
                })


            }
            else {
                this.ErrorMessage("礼品卡为空。");
            }
        },
        //显示错误信息
        ErrorMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'error'
            });
        },
        //显示通过信息
        SuccessMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'success'
            });
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
        }),
    },
    mounted() {
        //写入用户数据

    },
}
</script>
