import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router'
import store from './store'
import Cookie from 'js-cookie'
import { getTokenInformation } from "./api"

Vue.config.productionTip = false;
Vue.use(ElementUI);

// 添加全局前置导航守卫，未登录进入登录界面
router.beforeEach((to, from, next) => {
  const token = Cookie.get('token');
  if (!token && to.name == 'user') {
    next({ name: 'login' });
  } else if (token && to.name == 'login') {
    next({ name: 'home' });
  } else {
    next();
  }
});

export const EventBus = new Vue(); // 创建事件总线

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 添加用户信息
    const token = Cookie.get('token');
    if (token) {
      getTokenInformation(token).then((data) => {
        if (data.data.code !== 200) {
          Cookie.remove('token');
        } else {
          store.commit('setUserInfo', data.data.data);
        }
      });
    }
  }
}).$mount('#app');
