<template>
    <div class="detail">
        <div style="font-weight: bold;color: rgba(99,99,99,1);font-size: 18px;"> {{ news.title }} </div>
        <div
            style="display: flex; justify-content: space-between; margin-top: 14px;color: rgba(34,52,100,1);font-size: 14px;">
            <div style="display: flex;">
                <div> {{ formattedDateTime }}</div>
                <div style="margin-left:20px;"> {{ news.source }}</div>
            </div>
            <div style="display: flex; align-items: center;max-width: 70%;">
                <div v-if="news.relevantCompany"> 相关公司：{{ news.relevantCompany }}</div>
                <div v-if="news.relevantIndustry" style="margin-left: 20px;"> 相关行业：{{ news.relevantIndustry }}</div>
            </div>
        </div>
        <div style="margin-top: 25px;">
            <iframe :src="secureUrl" style="height: 650px; width: 100%"></iframe>
        </div>
        <el-link :href="handleClick()" target="_blank"
            style="margin-top: 50px;line-height: 20px;color: rgba(34,52,100,1);font-size: 14px;text-align: left;text-decoration: underline;">
            查看全文</el-link>
    </div>
</template>


<style lang="less" scoped>
.detail {
    height: 800px;
    margin: 20px;

}
</style>

<script>


import { sendPhoneVerificationCode, loginWithVerificationCode, loginWithPassword } from "../api"
import { mapState, mapMutations } from 'vuex';
export default {
    props: ['news'],
    data() {
        return {
            src: '../assdts/1219968279_0-9.pdf',
            E: {
                score: 8,
                category: "宏观",
                relevantCompany: "",
                relevantIndustry: "",
                picUrls: "",
                content: "中国物流与采购联合会在第三届中国政企采购数字供应链生态峰会上发布《中国公共采购发展报告（2023）》，全国公共采购总额46万亿元，同比下降4.16%。报告显示，2023年我国公共资源交易工程建设项目招标投标、政府采购、土地使用权和矿业权出让、国有产权交易四大板块交易数量176万个，同比增长17%；交易额达21.9万亿元。",
                title: "46万亿！去年我国公共采购“真金白银”花在这些地方",
                dateTime: "2024-05-10T13:54:42",
                source: "新闻资讯",
                web: ""
            }
        };
    },
    methods: {
        handleClick() {
            if (this.news.web) {
                return this.news.web;
            }
            else {
                return "https://www.baidu.com/s?wd=" + encodeURIComponent(this.news.content);
            }
        }

    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
        }),
        secureUrl() {
            if (this.news.web.startsWith('http://')) {
                return this.news.web.replace('http://', 'https://');
            }
            return this.news.web;
        },
        formattedDateTime() {
            // 创建一个新的 Date 对象
            const date = new Date(this.news.dateTime);
            // 格式化年份
            const year = date.getFullYear();
            // 格式化月份，月份从0开始所以加1，保证两位数表示
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            // 格式化日期，保证两位数表示
            const day = date.getDate().toString().padStart(2, '0');
            // 格式化小时，保证两位数表示
            const hours = date.getHours().toString().padStart(2, '0');
            // 格式化分钟，保证两位数表示
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // 组合成最终的格式
            return `${year}年${month}月${day}日 ${hours}:${minutes}`;
        },
    },
    components: {

    }


}
</script>