<template>
    <div class="detail">
        <div style="font-weight: bold;color: rgba(99,99,99,1);font-size: 18px;"> {{ news.title }} </div>
        <div
            style="display: flex; justify-content: space-between; align-items: center; margin-top: 14px; color: rgba(34,52,100,1); font-size: 14px;">
            <div style="display: flex; align-items: center;">
                <div> {{ formattedDateTime }}</div>
                <div style="margin-left: 20px;"> {{ news.source }}</div>
            </div>
            <div style="display: flex; align-items: center;max-width: 70%;">
                <div v-if="news.relevantCompany"> 相关公司：{{ news.relevantCompany }}</div>
                <div v-if="news.relevantIndustry" style="margin-left: 20px;"> 相关行业：{{ news.relevantIndustry }}</div>
            </div>
        </div>
        <div
            style="margin-top: 25px; line-height: 20px; color: rgba(99,99,99,1); font-size: 14px; text-align: justify;">
            {{ news.content }}
        </div>
        <div v-if="PicUrlArray && PicUrlArray.length"
            style="display: flex; flex-wrap: wrap; gap: 5px; margin-top: 20px;">
            <el-image v-for="(url, index) in visiblePicUrls" :key="index" style="width: 200px; height: 200px" :src="url"
                fit="contain" :preview-src-list="PicUrlArray"> </el-image>
            <div v-if="PicUrlArray.length > 3"
                style="width: 100px; height: 150px; display: flex; align-items: center; justify-content: center; font-size: 64px; color: #999;">
                ...
            </div>
        </div>
        <el-link :href="handleClick()" target="_blank"
            style="margin-top: 50px; line-height: 20px; color: rgba(34,52,100,1); font-size: 14px; text-align: left; text-decoration: underline;">
            查看更多信息
        </el-link>
    </div>
</template>

<style lang="less" scoped>
.detail {
    height: 800px;
    margin: 20px;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    props: ['news'],
    data() {
        return {
            PicUrlArray: [],
        };
    },
    methods: {
        handleClick() {
            if (this.news.web) {
                return this.news.web;
            } else {
                return "https://www.baidu.com/s?wd=" + encodeURIComponent(this.news.content);
            }
        },
        processNewsUrlString() {
            // 判断picUrls是否为空
            if (!this.news.picUrls) {
                // 当picUrls为空时，将PicUrlArray设为null
                this.PicUrlArray = null;
            } else {
                // 使用分号作为分隔符，将字符串分割成数组
                this.PicUrlArray = this.news.picUrls.split(';');
            }
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
        }),
        formattedDateTime() {
            // 创建一个新的 Date 对象
            const date = new Date(this.news.dateTime);
            // 格式化年份
            const year = date.getFullYear();
            // 格式化月份，月份从0开始所以加1，保证两位数表示
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            // 格式化日期，保证两位数表示
            const day = date.getDate().toString().padStart(2, '0');
            // 格式化小时，保证两位数表示
            const hours = date.getHours().toString().padStart(2, '0');
            // 格式化分钟，保证两位数表示
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // 组合成最终的格式
            return `${year}年${month}月${day}日 ${hours}:${minutes}`;
        },
        visiblePicUrls() {
            // 返回最多3个URL
            return this.PicUrlArray.slice(0, 3);
        }
    },
    created() {
        // 在组件创建完成后，立即处理字符串
        this.processNewsUrlString();
    },
}
</script>
