import * as signalR from '@microsoft/signalr';
import { EventBus } from '../main'; // 确保路径正确

class SignalRService {
  constructor() {
    // 初始化 SignalR 连接
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl('https://www.financestation.cn:9201/SignalR'
        //   , {
        //   skipNegotiation: true, // 跳过传输协议协商，直接使用WebSockets
        //   transport: signalR.HttpTransportType.WebSockets // 使用WebSockets作为传输协议
        // }
      )
      .configureLogging(signalR.LogLevel.Information) // 配置日志记录级别
      .build();

    // 监听连接关闭事件，自动重试连接
    this.connection.onclose(async () => {
      EventBus.$emit('connectionError', true); // 使用事件总线发送事件
      await this.start();
    });
  }

  // 启动连接方法
  async start() {
    // 如果连接状态是断开状态，尝试重新连接
    if (this.connection.state === signalR.HubConnectionState.Disconnected) {
      try {
        await this.connection.start();
        EventBus.$emit('connectionError', false); // 使用事件总线发送事件
        console.log('SignalR connected'); // 连接成功日志
        EventBus.$emit('signalRConnected'); // 发送SignalR连接成功事件
      } catch (err) {
        console.log('Error while starting SignalR connection: ' + err);
        // 如果连接失败，5秒后重试
        setTimeout(() => this.start(), 5000);
      }
    }
  }

  // 停止连接方法
  stop() {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.stop(); // 停止连接
    }
  }

  // 监听事件方法
  on(event, callback) {
    this.connection.on(event, callback); // 注册事件处理程序
  }

  // 取消监听事件方法
  off(event) {
    this.connection.off(event); // 取消事件处理程序
  }

  // 发送消息方法
  async send(event, data) {
    // 如果连接不是已连接状态，先尝试重新连接
    if (this.connection.state !== signalR.HubConnectionState.Connected) {
      await this.start();
    }
    try {
      // 调用服务器端方法并传递数据
      return await this.connection.invoke(event, data);
    } catch (err) {
      console.error(err);
    }
  }


  // 发送消息方法
  async send3(event, data1, data2) {
    // 如果连接不是已连接状态，先尝试重新连接
    if (this.connection.state !== signalR.HubConnectionState.Connected) {
      await this.start();
    }
    try {
      // 调用服务器端方法并传递数据
      return await this.connection.invoke(event, data1, data2);
    } catch (err) {
      console.error(err);
    }
  }

  // 加入群组方法
  async joinGroup(groupName) {
    await this.send('JoinGroup', groupName); // 调用服务器端的JoinGroup方法
  }

  // 离开群组方法
  async leaveGroup(groupName) {
    await this.send('LeaveGroup', groupName); // 调用服务器端的LeaveGroup方法
  }

  // 添加到新闻参数列表方法
  async addToNewsParameterList(newsTypeList, isContainUnimportantNews) {
    try {
      return await this.send3('AddToNewsParameterList', newsTypeList, isContainUnimportantNews); // 调用服务器端的AddToNewsParameterList方法
    } catch (err) {
      console.error('Error calling AddToNewsParameterList: ', err);
    }
  }

  // SignalR的token注册方法
  async tokenAuthorize(token) {
    try {
      return await this.send('TokenAuthorize', token); // 调用服务器端的TokenAuthorize方法
    } catch (err) {
      console.error('Error calling TokenAuthorize: ', err);
    }
  }
}

// 导出 SignalRService 实例
export default new SignalRService();
